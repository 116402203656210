import React, { useEffect, useState } from 'react';
import client from '../utils/shopify';
import { Link } from 'react-router-dom';

function ProductLine() {
  const [products, setProducts] = useState([]);

  const collectionQuery = `
      query CollectionQuery {
    collectionByHandle(handle: "recommendation") {
    products(first: 10) {
      nodes {
        id
        title
        handle
        images(first: 4) {
          nodes {
            src
            id
          }
        }
        priceRange {
          minVariantPrice {
            amount
          }
        }
      }
    }
    handle
  }
}`;

  const fetchCollection = async () => {
    const { data } = await client.request(collectionQuery);
    setProducts(data['collectionByHandle']['products']['nodes']);
  };

  useEffect(() => {
    fetchCollection();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1 style={{ marginTop: '50px' }}>Recommended</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
        {products.map((product) => (
          <Link to={`/products/${product['handle']}`} className='pal__item' key={product['id']}>
            <img
              height="auto"
              loading='lazy'
              decoding='async'
              src={product['images']['nodes'][0]['src']}
              alt={product['title']}
              onMouseEnter={e => (e.currentTarget.src = product['images']['nodes'][1]?.src || product['images']['nodes'][0]['src'])}
              onMouseOut={e => (e.currentTarget.src = product['images']['nodes'][0]['src'])}
            />
            <label className='pal__name'>{product['title']}</label>
            <p className='pal__price'>₹{product['priceRange']['minVariantPrice']['amount']}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ProductLine