import React, { useEffect, useState } from 'react'
import "../css/Cart.css"
import client from '../utils/shopify'
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function Cart({ setCartItemTotal, getCartId }) {
  const cartId = localStorage.getItem('cartId');
  const [cart, setCart] = useState([])
  const [lines, setLines] = useState([])

  const cartQuery = `query MyQuery($id: ID!) {
  cart(id: $id) {
    checkoutUrl
    totalQuantity
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    createdAt
    id
    lines(first: 20) {
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              product {
                id
                title
                media(first: 10) {
                  nodes {
                    ... on MediaImage {
                      image {
                        url
                      }
                    }
                  }
                }
              }
              selectedOptions {
                name
                value
              }
            }
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
  }
}`

  const cartUpdateQuery = `
      mutation MyMutation($cartId: ID!, $id: ID!, $quantity: Int) {
        cartLinesUpdate(
          cartId: $cartId,
          lines: {id: $id, quantity: $quantity}
        ) {
          cart {
            checkoutUrl
          }
        }
      }`

  const fetchCart = async () => {
    const { data, error } = await client.request(cartQuery, {
      variables: {
        id: cartId
      }
    })
    console.log(data)

    if(data['cart'] != null){
     
      if (data['cart'] !== undefined) {
        setCart(data['cart'])
        console.log(error)
        setCartItemTotal(data['cart']['totalQuantity'])
        if (data['cart']['totalQuantity'] > 0) {
          setLines(data['cart']['lines']['edges'])
          console.log(data['cart']['lines']['edges'])
        }
      }
    } else {
      getCartId()
    }

    

  }

  const updateCartLine = async (cartLine, quantity) => {
    const { data, error } = await client.request(cartUpdateQuery, {
      variables: {
        cartId: cartId,
        id: cartLine,
        quantity: quantity
      }
    })
    console.log(data)
    console.log(error)
    if (data) {
      fetchCart()
    } else {
      console.log("No data")
    }
  }

  useEffect(() => {
    fetchCart()
  }, [])

  useGSAP(()=>{
    gsap.from('.cart', {
      y: -200,
      opacity: 0,
      duration: 1,
      delay:0.5
      
    })

  })  

  return (
    <div className='cart'>

      {
        cart['totalQuantity'] >= 1
          ? <div className='cart__holder'>
            <div className='cart__lines'>
              <div className='cart__topbar'>
                <label>PRODUCT</label>
                <label>NAME</label>
                <label>PRICE</label>
                <label>SIZE</label>
                <label>QUANTITY</label>
                <label>TOTAL</label>
              </div>
              <div className='cart__border'></div>
              {
                lines.map((item) => (
                  <div className='cart__line'>
                    <img alt='cartImage' src={item['node']['merchandise']['product']['media']['nodes'][3]['image']['url']} />
                    <div className='cart__info'>
                      <img alt='cartImage' src={item['node']['merchandise']['product']['media']['nodes'][3]['image']['url']} />
                      <label style={{ marginLeft: '0px' }} className='tit'>{item['node']['merchandise']['product']['title']}</label>
                      <label><span>₹</span>{item['node']['cost']['amountPerQuantity']['amount']}</label>
                      <label>{item['node']['merchandise']['selectedOptions'][0]['value']}</label>
                      <div className='quantity__controller'>
                        <button onClick={() => { updateCartLine(item['node']['id'], item['node']['quantity'] - 1) }}><span>-</span></button>
                        <label>{item['node']['quantity']}</label>
                        <button onClick={() => { updateCartLine(item['node']['id'], item['node']['quantity'] + 1) }}><span>+</span></button>
                      </div>
                      <label style={{ opacity: '0.7' }}><span>₹</span>{item['node']['cost']['totalAmount']['amount']}</label>
                    </div>
                  </div>
                ))
              }
              <div className='cart__border'></div>
            </div>

            <div className='checkbox__holder'>
              <div className='checkout__box'>
                <label className='checkout__title'>ORDER SUMMERY</label>
                <div className='subtotal'>
                  <span>Subtotal</span>
                  <span><p>₹</p>{cart['cost']['subtotalAmount']['amount']}</span>
                </div>

                <div className='total'>
                  <span>{'TOTAL (VAT INCLUDED)'}</span>
                  <span><p>₹</p>{cart['cost']['totalAmount']['amount']}</span>
                </div>
                <div className='checkout__button'>
                  <a rel='noreferrer' href={cart['checkoutUrl']}><span>GO TO CHECKOUT</span></a>
                </div>
                <ul className='payment__icon'>
                  <li><img alt='google' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/dcdfe7e1d5626b0a1dda.svg' /></li>
                  <li><img alt='paypal' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg' /></li>
                  <li><img alt='maestro' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/bfaa13c64b6ea06f289f.svg' /></li>
                  <li><img alt='visa' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg' /></li>
                  <li><img alt='maestro' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/a1944e97954a433490a6.svg' /></li>
                  <li><img alt='visa' src='https://cdn.shopify.com/shopifycloud/checkout-web/assets/fe904f1307590b94f8e6.svg' /></li>

                </ul>
              </div>

            </div>
          </div>
          : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'column-reverse' }}>
            <div style={{ position: 'absolute', top: '30%' }}>


              <img src='https://cdn-icons-png.flaticon.com/512/1174/1174408.png' width={200} />
              <div style={{ height: '50px' }}></div>
              <h1>Cart is empty</h1>
            </div>
          </div>
      }

    </div>
  )
}

export default Cart

/**<ul className='payment__icon'>
                                    <li><img alt='google' src='https://a.storyblok.com/f/161230/x/a44ccf98d4/payment-google.svg' /></li>
                                    <li><img alt='paypal' src='https://a.storyblok.com/f/161230/x/fcaef8b7dd/payment-paypal.svg' /></li>
                                    <li><img alt='visa' src='https://a.storyblok.com/f/161230/x/88bd200ca5/payment-visa.svg' /></li>
                                    <li><img alt='maestro' src='https://a.storyblok.com/f/161230/x/c9507b9f5e/payment-maestro.svg' /></li>
                                    <li><img alt='apple' src='https://a.storyblok.com/f/161230/x/5bc120e0f8/payment-apple.svg' /></li>
                                </ul> */


/** <svg preserveAspectRatio="none" name="Shape top variants" viewBox="0 0 343 12" class="sc-29bbdc9f-2 dLdiIl"><path d="M0 4a4 4 0 0 1 4-4h230.52a6 6 0 0 1 4.24 1.76l4.48 4.48A6 6 0 0 0 247.48 8H339a4 4 0 0 1 4 4H0Z" fill="white"></path></svg>
 * <svg name="Shape bottom variants" viewBox="0 0 343 12" preserveAspectRatio="none" class="sc-29bbdc9f-3 iIkCPZ"><path d="M343 8a4 4 0 0 1-4 4H108.49a6 6 0 0 1-4.25-1.76l-4.48-4.48A6 6 0 0 0 95.51 4H4a4 4 0 0 1-4-4h343Z" fill="white"></path></svg>
 */

/**Cart: 
            <a href={cart['checkoutUrl']}>Checkout</a> */


/**                                <div className='shipping'>
                        <span>Tax</span>
                        <span>Calculated at checkout</span>
                    </div> */

/** <svg preserveAspectRatio="none" viewBox="0 0 916 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-aca23b20-0 caHgRi"><path d="M0 8.5h286.34a4 4 0 0 0 2.83-1.17l5.66-5.66A4 4 0 0 1 297.66.5H917" stroke="currentColor"></path></svg>
 * <svg preserveAspectRatio="none" viewBox="0 0 916 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="sc-e7935161-0 kPUtbz"><path d="M0 .5h286.34a4 4 0 0 1 2.83 1.17l5.66 5.66a4 4 0 0 0 2.83 1.17H917" stroke="currentColor"></path></svg>
 */


/**`
query MyQuery($id: ID!) {
cart(
id: $id
) {
checkoutUrl
totalQuantity
cost {
subtotalAmount {
amount
currencyCode
}
totalAmount {
amount
currencyCode
}
}
createdAt
id
lines(first: 10) {
edges {
node {
id
merchandise {
... on ProductVariant {
id
image {
url
}
product {
id
title
}
selectedOptions {
name
value
}
}
}
cost {
amountPerQuantity {
amount
currencyCode
}
subtotalAmount {
amount
currencyCode
}
totalAmount {
amount
currencyCode
}
}
quantity
}
}
}
}
}` */