import React, { useEffect, useState } from 'react'
import '.././css/LandingPage.css'
import LOGO from "../assets/TYPOL.png"
import landingm from "../assets/landingm.mov"
import landing from "../assets/LANDING.mp4"
import landing3 from "../assets/vertical_landing.mp4"
import landing1 from "../assets/landing1.MOV"
import landing2 from "../assets/landing2.MOV"
import logosmall from "../assets/logo.svg"
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import PAL from '../components/PAL';
import ProductLine from '../components/ProductLine'

function LandingPage({ loadingPage }) {

  var width = window.innerWidth;

  if (loadingPage) return (
    <div className='loading__page'>

      <div className='loading__holder'>
        <div className='first__container'>
          <span>26</span>
          <span>01</span>
          <span>02</span>
          <span>03</span>
          <span>04</span>
          <span>05</span>
          <span>06</span>
          <span>07</span>
          <span>08</span>
          <span>09</span>
          <span>10</span>
          <span>11</span>
          <span>12</span>
          <span>13</span>
          <span>14</span>
          <span>15</span>
          <span>16</span>
          <span>17</span>
          <span>18</span>
          <span>19</span>
          <span>20</span>
          <span>20</span>
          <span>21</span>
          <span>22</span>
          <span>23</span>
          <span>24</span>
          <span>25</span>
          <span>26</span>
          <div className='top-gradient'></div>
          <div className='bottom-gradient'></div>
        </div>
        <div className='middle__container'>
          <span>/</span>
          <span>of</span>
          <span>/</span>
          <div className='top-gradient'></div>
          <div className='bottom-gradient'></div>
        </div>
        <div className='last__container'>
          <span>26</span>
        </div>

      </div>
    </div>
  );

  return (
    <Fade bottom duration={500}>
      <div className='landing__page'>
        <div className='hero__section'>
          <div class="hero"  >
            <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? 'https://cdn.shopify.com/videos/c/o/v/b078f699e9914b3eaa8c0b2778f17e9a.mp4' : 'https://cdn.shopify.com/videos/c/o/v/d950bdaf9ce248af92157289c821ffa7.mp4'} />
            
          </div>
          <div className="hero" style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: width > 1000 ? 'start' : 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            boxSizing: 'border-box' // Prevents overflow
          }}>

            {/* Left Section - Women's Collection */}
            <div style={{ position: 'relative', width: width > 1000 ? '50%' : '100%' }}>
              <img
                loading="eager"
                alt="woman-collection"
                src={width > 1000 ? 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/F0A894111.jpg?v=1740744046' : "https://cdn.shopify.com/s/files/1/0643/2787/6757/files/F0A894111.jpg?v=1740744046"}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover', // Ensures proper scaling
                  display: 'block'
                }}
              />
              <div className="mid__link" style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                fontWeight: 'bold',
                padding: '5px 10px',
                borderRadius: '5px',
              }}>
                <Link style={{color: 'white'}} to={'/men'}>SHOP MAN {'/'} NEW ARRIVALS</Link>
              </div>
            </div>

            {/* Right Section - Men's Collection */}
            <div style={{ position: 'relative', width: width > 1000 ? '50%' : '100%' }}>
              <img
                loading="eager"
                alt="man-collection"
                src={width > 1000 ? 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/F0A8178-21.jpg?v=1740744046' : "https://cdn.shopify.com/s/files/1/0643/2787/6757/files/F0A8178-21.jpg?v=1740744046"}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover', // Ensures proper scaling
                  display: 'block'
                }}
              />
              <div className="mid__link" style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                fontWeight: 'bold',
                padding: '5px 10px',
                borderRadius: '5px'
              }}>
                <Link style={{color: 'white'}} to={'/women'}>SHOP WOMEN {'/'} NEW ARRIVALS</Link>
              </div>
              
            </div>
          </div>
          <ProductLine/>
        </div>
      </div>
    </Fade>
  )
}

export default LandingPage

/*<div className='hero__text'><Link to='/ourjourney'>Our Journey</Link></div>*/

/**<div className='hero__text'>COLLECTIONS</div> */

/** <div class="hero" style={{minHeight: '100vh'}}>
          <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? landing1 : "https://videos.pexels.com/video-files/6010502/6010502-hd_1080_1920_25fps.mp4"} />
        </div>

        <div class="hero" style={{minHeight: '100vh'}}>
          <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? landing2 : "https://videos.pexels.com/video-files/4599004/4599004-hd_1080_1920_30fps.mp4"} />
        </div> */


/*<div className="hero" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: width > 1000 ? 'row' : 'column', position: 'relative' }}>
    <div style={{ position: 'relative' }}>
      <img
        loading='eager'
        alt='hero'
        src={width > 1000 ? 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/WhatsApp_Image_2024-10-06_at_23.11.18_50bf909b.jpg?v=1728236564' : "https://cdn.shopify.com/s/files/1/0643/2787/6757/files/WhatsApp_Image_2024-10-06_at_23.11.18_50bf909b.jpg?v=1728236564"}
      />
      <div className='nav__link ' style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <Link style={{ color: 'white' }} to={'/collections'}>COLLECTIONS</Link>
      </div>
    </div>

    <div style={{ position: 'relative' }}>
      <img
        loading='eager'
        alt='hero'
        src={width > 1000 ? 'https://cdn.shopify.com/s/files/1/0643/2787/6757/files/WhatsApp_Image_2024-10-06_at_23.11.38_6bac98ee.jpg?v=1728236563' : "https://cdn.shopify.com/s/files/1/0643/2787/6757/files/WhatsApp_Image_2024-10-06_at_23.11.38_6bac98ee.jpg?v=1728236563"}
      />
      <div className='nav__link' style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

      }}>
        <Link style={{ color: 'white' }} to={'/women'}>WOMEN</Link>
      </div>
    </div>
  </div>*/

  /*  'https://cdn.shopify.com/videos/c/o/v/fdc7c9ec8be74a7f989a58079c89676a.mov' */