import React from 'react'
import "../css/PrivacyPolicy.css"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

function ShippingPolicy() {

    const height = window.innerHeight;

    useGSAP(() => {



        const tl = gsap.timeline()

        tl.from('.privacy__scrollformore', {
            y: 5,
            yoyo: true,
            repeat: -1,
            defaults: { // <-- important
                duration: 2,
                ease: "none"
            }
        })
        gsap.from(".privacyblock", {
            opacity: 0,
            delay: 0.5,
            y: 100,
            stagger: 1,
            ease: 'power1.inOut',
            scrollTrigger: {
                scrub: true,
                /*markers: true,*/
                trigger: '.privacypolicy',
                start: 'top 50%',
                end: height >= 1500 ? 'bottom 80%' : 'bottom 50%'
            }
        })

    })
    return (
        <div className="privacypolicy">
            {
                height >= 900
                    ? <div className="privacy__scrollformore">
                        <h5>Scroll</h5>
                        <h5> For </h5>
                        <h5>More</h5>
                    </div>
                    : <></>
            }

            <div className="privacypolicy__container" >
                <div className="privacypolicy__content" >
                    <p >
                        <div className='privacyblock'>
                            <h1 data-scroll>Shipping policy
                            </h1>
                            <h5 data-scroll>It's important to start by clarifying to customers that your order processing times are separate from the shipping times they see at checkout.
                                All orders are processed within 5 TO 7  business days (excluding weekends and holidays) after receiving your order confirmation email. You will receive another notification when your order has shipped.
                                Include any other pertinent information towards the beginning, such as potential delays due to a high volume of orders or postal service problems that are outside of your control.
                                Domestic Shipping Rates and Estimates
                                For calculated shipping rates: Shipping charges for your order will be calculated and displayed at checkout.

                                Shipping option
                                Local delivery or in-store pickup to customers in your area, you can dedicate a section of your shipping policy page to explain the process or create a separate shipping page specifically for local customers.
                                Free local delivery is available for orders over  7000 within [Thane]. For orders under  7000, we charge  20 RS for local delivery.
                                Deliveries are made from [9 AM- 9PM] on [mon - fri]. We will contact you via text message with the phone number you provided at checkout to notify you on the day of our arrival.

                                In-store pickup
                                You can skip the shipping fees with free local pickup at [list the locations where in-store pickup is available]. After placing your order and selecting local pickup at checkout, your order will be prepared and ready for pick up within 4 to 5 business days. We will send you an email when your order is ready along with instructions.
                                Our in-store pickup hours are [10 AM - 9 PM] on [All days]. Please have your order confirmation email with you when you come.
                                International Shipping
                                If relevant you can also include countries you don’t ship to: At this time, we do not ship to [All Global codes].
                                If you’re using calculated shipping rates: Shipping charges for your order will be calculated and displayed at checkout.
                                If you offer multiple international shipping options, you can list them in a table as well. You can include broader delivery timelines 8 to 20 days for international shipping since expectations can vary greatly depending on the destination.



                                Your order may be subject to import duties and taxes (including VAT), which are incurred once a shipment reaches your destination country. [2WENT6EX] is not responsible for these charges if they are applied and are your responsibility as the customer.</h5>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ShippingPolicy