import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import FaqPage from './pages/FAQPage';
import ClientAdvisorPage from './pages/ClientAdvisorPage'
import BookAppointmentPage from './pages/BookAppointmentPage'
import ArtOfGiftingPage from './pages/ArtOfGiftingPage'
import NoMatch from './pages/NoMatch';
import AboutUsPage from './pages/AboutUsPage';
import CareersPage from './pages/CareersPage';
import SustainabilityPage from './pages/SustainabilityPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import LOGO from "./assets/TYPOL.png";
import NUMONIC from "./assets/numonic.png";
import Collections from './pages/Collections';
import ProductPage from './pages/ProductPage';
import Cart from './pages/Cart';
import { useEffect, useState } from 'react';
import client from './utils/shopify';
import Profile from './pages/Profile';
import Register from './pages/Register';
import ContactUs from './pages/ContactUs';
import InsideTheBox from './pages/InsideTheBox';
import ScrollToTop from './components/Scroll-To-Top';
import Login from './pages/Login';
import SearchPage from './pages/SearchPage';
import OurJourney from './pages/OurJourney';
import ArtPage from './pages/ArtPage';
import Women from './pages/Women';
import Men from './pages/Men.js';
import { logEvent } from 'firebase/analytics';
import analytics from './firebase.js';
import LineWorkCollection from './pages/LineWorkCollection.js';
import RefundPolicy from './pages/RefundPolicy.js';
import ShippingPolicy from './pages/ShippingPolicy.js';
import NO26Collection from './pages/NO26Collection.js';
import TOPS from './pages/categories/Tops.js';
import SHIRTS from './pages/categories/Shirts.js';
import OUTERWEAR from './pages/categories/Outerwear.js';
import CORSET from './pages/categories/Corset.js';
import BOTTOMS from './pages/categories/Bottoms.js';
import JEANS from './pages/categories/Jeans.js';
import DRESSES from './pages/categories/Dresses.js';
import OurJourney1 from './pages/OurJourney1.js';
import Sales from './pages/Sales.js';

function App() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [cartItemTotal, setCartItemTotal] = useState(0);
  const [on, setOn] = useState(false);

  setTimeout(() => {
    setLoadingPage(false)
    document.querySelector("meta[name='theme-color']").content = "#E9E6E1";
  }, 6000);

  const [cartId, setCartId] = useState('');

  const cartCreateQuery = `
  mutation MyMutation {
    cartCreate(input: {note: "2Went6ex Cart"}) {
      cart {
        id
      }
    }
  }
  `

  const getCartId = async () => {
    const { data, error } = await client.request(cartCreateQuery);
    const cartId = data['cartCreate']['cart']['id']
    setCartId(cartId)
    localStorage.setItem('cartId', cartId)
  }

  useEffect(() => {
    console.log(window.location.pathname)
    if (localStorage.getItem('cartId') != null) {
      console.log(localStorage.getItem('cartId'))
      setCartId(localStorage.getItem('cartId'))
    } else {
      getCartId()
    }
  }, [])

  useEffect(()=>{

    logEvent(analytics, 'website visited');
    console.log('website visited')
  },[])


  return (
    <Router>
  <div className="app">
    {/* Hide Header for '/pricedrop' */}
    {window.location.pathname !== '/pricedrop' && 
      (window.location.pathname === '/' && loadingPage ? null : <Header cartItemTotal={cartItemTotal} setOn={setOn} on={on} />)
    }

    <div className='layout'>
      <Routes>
        <Route path='/' index element={<LandingPage loadingPage={loadingPage} setLoadingPage={setLoadingPage} />} />
        <Route path='/collection' element={<Navigate to={'/collections'} />} />
        <Route path='/collections' element={<Collections />} />
        {/* Categories */}
        <Route path='/tops' element={<TOPS />} />
        <Route path='/shirts' element={<SHIRTS />} />
        <Route path='/outerwear' element={<OUTERWEAR />} />
        <Route path='/jeans' element={<JEANS />} />
        <Route path='/dresses' element={<DRESSES />} />
        <Route path='/corset' element={<CORSET />} />
        <Route path='/bottoms' element={<BOTTOMS />} />
        {/* */}
        <Route path='/no-26' element={<NO26Collection />} />
        <Route path='/linework' element={<LineWorkCollection />} />
        <Route path='/pricedrop' element={<Sales />} />
        <Route path='/products/:id' element={<ProductPage />} />
        <Route path='/cart' element={<Cart cartId={cartId} getCartId={getCartId} setCartItemTotal={setCartItemTotal} />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/art' element={<ArtPage />} />
        <Route path='/artofgifting' element={<ArtOfGiftingPage />} />
        <Route path='/aboutus' element={<AboutUsPage />} />
        <Route path='/bookanappointment' element={<BookAppointmentPage />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/careers' element={<CareersPage />} />
        <Route path='/clientadvisor' element={<ClientAdvisorPage />} />
        <Route path='/sustainability' element={<SustainabilityPage />} />
        <Route path='/privacypolicy' element={<PrivacyPolicyPage />} />
        <Route path='/refundpolicy' element={<RefundPolicy />} />
        <Route path='/shippingpolicy' element={<ShippingPolicy />} />
        <Route path='/faq' element={<FaqPage />} />
        <Route path='/inside-the-box' element={<InsideTheBox />} />
        <Route path='/women' element={<Women />} />
        <Route path='/men' element={<Men />} />
        <Route path="*" element={<NoMatch />} /> 
      </Routes>
    </div>

    {/* Hide Footer for '/pricedrop' */}
    {window.location.pathname !== '/pricedrop' && 
      (window.location.pathname === '/' && loadingPage ? null : <Footer />)
    }

    <ScrollToTop />
  </div>
</Router>

  );
}

export default App;


/** */

/**      <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' ,minHeight: '100vh'}}>
<img src={NUMONIC} width={100} />
<img src={LOGO} width={100} />
<h1 style={{fontSize: '25px'}}>SITE UNDER MAINTAINCE</h1>
<img style={{margin: '20px'}} src='https://cdnl.iconscout.com/lottie/premium/thumb/website-under-maintenance-5690953-4747761.gif' />
</div> */

/**<Route path='/collection' element={<Navigate to={'/collections'} />} />
            <Route path='/collections' element={<Collections />} />
            <Route path='/products/:id' element={<ProductPage />} />
            <Route path='/cart' element={<Cart cartId={cartId} setCartItemTotal={setCartItemTotal} />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/art' element={<ArtPage />} />
            <Route path='/artofgifting' element={<ArtOfGiftingPage />} />
            <Route path='/aboutus' element={<AboutUsPage />} />
            <Route path='/ourjourney' element={<OurJourney/>}/>
            <Route path='/bookanappointment' element={<BookAppointmentPage />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/careers' element={<CareersPage />} />
            <Route path='/clientadvisor' element={<ClientAdvisorPage />} />
            <Route path='/sustainability' element={<SustainabilityPage />} />
            <Route path='/privacypolicy' element={<PrivacyPolicyPage />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/inside-the-box' element={<InsideTheBox />} />
            <Route path='/women' element={<Women />}/>
            <Route path='/men' element={<Men />}/>
            <Route path="*" element={<NoMatch />} /> */