import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import '../../css/Collections.css'
import client from '../../utils/shopify'
import { Link } from 'react-router-dom'
import LocomotiveScroll from 'locomotive-scroll'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

function DRESSES() {
    const [products, setProducts] = useState([])
    const collectionQuery = `
    query LineWork {
  collection(handle: "dresses") {
    products(first: 100) {
              nodes {
                id
                title
                handle
                images(first: 4) {
                  nodes {
                    src
                    id
                  }
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
              }
    }
  }
}`
    const productsDomNodes = [];

    const fetchCollection = async () => {
        const { data, errors } = await client.request(collectionQuery)
        console.log(data['collection']['products']['nodes'])
        setProducts(data['collection']['products']['nodes'])
    }

    useEffect(function () {
        fetchCollection()

    }, [])

    useGSAP(() => {
        gsap.from('.collection__detail', {
            y: 200,
            opacity: 0,
            duration: 1
        })
    })


    return (
        <div className='collections__page' >

            <div className='collection__detail' >
                {
                    products.map((product) => (
                        <Link to={`/products/${product['handle']}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', color: 'black' }}>

                            <div className='collection__item' >

                                <img loading='lazy' decoding='async' src={product['images']['nodes'][0]['src']} onMouseEnter={e => (e.currentTarget.src = product['images']['nodes'][3]['src'])} onMouseOut={e => (e.currentTarget.src = product['images']['nodes'][0]['src'])} />
                                <label>{product['title']}</label>
                                <p>₹{product['priceRange']['minVariantPrice']['amount']}</p>
                            </div>
                        </Link>
                    ))

                }
            </div>
        </div>
    )
}

export default DRESSES


/**            <h1>Collections</h1> */

/**
                <span style={{color: '#6f121c', position: 'absolute',bottom: '-10px'}}>Sold out</span> */