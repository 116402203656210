import React, { useEffect, useState } from 'react'
import '../css/Collections.css'
import client from '../utils/shopify'
import { Link, useParams } from 'react-router-dom'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

function Men() {
    const [productsM, setProductsM] = useState([])
    const menColletion = `
    query CollectionCall {
  collectionByHandle(handle: "new-arrival-men") {
    handle
    products(first: 100) {
      nodes {
        id
        title
        handle
        images(first: 2) {
          nodes {
            src
            id
          }
        }
        priceRange {
          minVariantPrice{
            amount
          }
        }
      }
    }
  }
}
`
    const fetchCollection = async () => {
        const { data, errors } = await client.request(menColletion)
        console.log(data['collectionByHandle']['products']['nodes'])
        setProductsM(data['collectionByHandle']['products']['nodes'])
    }

    useEffect(function () {
        fetchCollection()
    }, [])

    useGSAP(()=>{
        gsap.from('.collection__detail', {
          y: 200,
          opacity: 0,
          duration: 1
        })
      })
    

    return (
        <div className='collections__page' >
<div className='collection__detail' >
                {
                    productsM.map((product) => (
                        <Link to={`/products/${product['handle']}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', color: 'black' }}>

                            <div className='collection__item' >
                                <img loading='lazy' decoding='async' src={product['images']['nodes'][0]['src']} onMouseEnter={e => (e.currentTarget.src = product['images']['nodes'][1]['src'])} onMouseOut={e => (e.currentTarget.src = product['images']['nodes'][0]['src'])} />
                                <label>{product['title']}</label>
                                <p>₹{product['priceRange']['minVariantPrice']['amount']}</p>
                            </div>
                        </Link>
                    ))

                }
            </div>
        </div>
    )
}

export default Men
/**
            <div className='collection__detail' >
                {
                    products.map((product) => (
                        <Link to={`/products/${product['handle']}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', color: 'black' }}>

                            <div className='collection__item' >
                                <img loading='lazy' decoding='async' src={product['images']['nodes'][0]['src']} onMouseEnter={e => (e.currentTarget.src = product['images']['nodes'][1]['src'])} onMouseOut={e => (e.currentTarget.src = product['images']['nodes'][0]['src'])} />
                                <label>{product['title']}</label>
                                <p>₹{product['priceRange']['minVariantPrice']['amount']}</p>
                            </div>
                        </Link>
                    ))

                }
            </div> */